/*
 * Base structure
 */
html, body {
	 width: 100%;
	 height: 100%;
	 margin: 0 !important;
	 padding: 0 !important;
	 font-family: 'Roboto', Helvetica, Arial, sans-serif;
	 background-color: transparent;
	/* All apps are wrapped in a centered/max-width 1920px container */
}
 html .app-wrapper, body .app-wrapper {
	 display: flex;
	 width: 100%;
	 max-width: 1920px;
	 margin: 0 auto;
}
 html .app-wrapper .app-body, body .app-wrapper .app-body {
	 align-content: center;
	 width: 100%;
	 background-color: #fff;
}
 html .pointer-item, body .pointer-item {
	 cursor: pointer;
}
 html .fill-height, body .fill-height {
	 height: 100%;
	 display: flex;
	 flex-direction: column;
}
 html .textTruncate, body .textTruncate {
	 white-space: nowrap;
	 overflow: hidden;
	 text-overflow: ellipsis;
	 display: inline-block;
}

