:root {
    --white: #fff;
    --darkBlack: rgba(0, 0, 0, 0.87);
}

/* IMPORTANT: we must keep header-container class as this is use by EMERGE as a hook to hide the nav*/
.nav-top.header-container {
    display: flex;
    color: var(--white);
    background-color: #002D5B !important;
    font-size: 16px;
    box-shadow: none !important;
   /* we don't want to remove from all appbars, just the nav */
    height: 64px;
    margin-bottom: 0;
}
.nav-top.header-container .app-top-toolbar {
    padding: 0 10px;
    height: 100%;
    max-height: none;
}
.nav-top.header-container .app-top-toolbar .app-menu-wrapper {
    display: flex;
    flex: 1;
    align-items: center;
}
.nav-top.header-container .app-top-toolbar .app-menu-wrapper .app-menu {
    height: 44px;
    width: 44px;
    color: var(--white);
    margin-right: 10px;
    padding-top: 0;
    position: relative;
}
.nav-top.header-container .app-top-toolbar .app-menu-wrapper .app-menu svg {
    color: var(--white);
    position: absolute;
    top: 10px;
}

/* Mobile nav drawer */
.app-top-toolbar-drawer-content {
    width: 320px;
    color: var(--darkBlack);
}
.app-top-toolbar-drawer-content .company-logo-wrapper {
    position: relative;
    height: 120px;
}
.app-top-toolbar-drawer-content .company-logo-wrapper img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 240px;
    max-height: 120px;
}
.app-top-toolbar-drawer-content .app-top-toolbar-drawer-header {
    height: 60px;
    color: var(--darkBlack);
    display: flex;
    flex: 1;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
}
.app-top-toolbar-drawer-content .app-top-toolbar-drawer-header svg {
    font-size: 18px;
    margin-right: 24px;
    color: var(--darkBlack);
    cursor: pointer;
}
.app-top-toolbar-drawer-content .app-top-toolbar-drawer-header h2 {
    font-size: 18px;
    color: var(--darkBlack);
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}


/* Responsive styles - Mobile first so these deal with > 360 */
@media (min-width: 960px) {
    .app-top-toolbar-drawer-header {
        padding-left: 16px;
        padding-right: 16px;
    }
}

@media (min-width: 1280px) {
    .nav-top .menu-items-wrapper a {
        padding: 0 30px;
   }
}
